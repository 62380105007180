.header-main {
  background-color: var(--color-base-light);
  padding: 20px 0;
  position: relative;
  z-index: 99;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

.header-main.no-search {
  padding-bottom: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 64px;
  padding: 0 20px;
  border: none;
}

.top-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
}

.header__title {
  flex-shrink: 1;
  font-size: 25px;
  font-weight: var(--font-weight-bolder);
  color: var(--color-basic-info);
  line-height: 30px;
  margin: 0;
  padding: 10px;
  text-align: center;
}

.header__partner-icon {
  flex-shrink: 1;
  max-width: 150px;
  max-height: 50px;

  @media (max-width: 500px) {
    max-width: 100px;
  }
}

.header__menu-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}
