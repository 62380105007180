.recording-call-player {
  width: 100%;
}

.react-player {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.react-player .player {
  background-color: transparent !important;
}

.react-player .controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.border-line {
  margin: 15px 0 20px 0;
  width: 100%;
  opacity: 0.2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}