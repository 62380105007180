.appointment-modal-item {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 0;
}

.appointment-modal-item__icon {
  padding: 30px;
  border: 1px solid var(--color-primary-2);
  width: 45px;
  background-color: transparent;
  color: var(--color-primary-2);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 0 10px;
}

.appointment-modal-item__date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appointment-modal-item__date-day {
  font-size: 28px;
  font-weight: 600;
}

.appointment-modal-item__date-month {
  font-size: 15px;
}

.appointment-modal-item__info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: var(--font-size-normal);
}

.appointment-modal-item__general-info {
  color: var(--color-general-info);
  margin-right: 5px;
}

.appointment-modal-item__highlighted-info {
  color: var(--color-appointment-highlighted-info);
  font-weight: bold;
}

.appointment-modal-item__sentence {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.appointment-modal-item__location {
  display: flex;
  gap: 5px;
}
