.wrapper:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.private-call {
  background: linear-gradient(to left, var(--color-base-light), var(--color-darker-white));
  opacity: 0.8;
}

.recording-item-card {
  display: flex;
  width: 100%;
  padding: 20px 0;
  gap: 0 !important;
}

.recording-item-card__info-wrapper {
  width: 100%;
  margin-left: 10px;
  position: relative;
}

.recording-item-card__info-description {
  font-size: 14px;
  font-weight: 700;
}

.recording-item-card_info-description {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-grey-info);
  margin-bottom: 10px;
}

.recording-item-card__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.recording-item-card__date {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-date);
  position: absolute;
  right: 0;
  top: 1px;
}

.recording-item-card__todos {
  padding: 0;
  font-size: 14px;
}

.recording-item-card__todos .custom-list-item {
  padding: 0;
}

.recording-item-card .todo-item__task {
  padding: 0;
}

.recording-item-card__duration {
  font-size: 9px;
  color: var(--color-duration-time);
  margin-top: 5px;
  font-weight: var(--font-weight-bolder);
}

@media(max-width: 768px) {

  .recording-item-card__name {
    width: calc(100% - 80px);
  }
}