.confirmation-modal {
  max-width: 400px;
  max-height: 230px;
  min-height: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1000;
}

.text-align {
  margin-bottom: 10px;
}

.confirmation-modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.confirm-button, .cancel-button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  width: 160px;
  height: 63px;
}

.confirm-button {
  background-color: var(--color-primary);
  color: var(--color-base-light);
}

.cancel-button {
  background-color: var(--color-priority);
  color: var(--color-base-light);
}

.confirmation-modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirmation-modal-header__title {
  font-weight: 600;
  font-size: 16px;
}
