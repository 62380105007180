main {
  display: flex;
  flex-direction: column;
}

main .container {
  flex: 1;
}

.list-container, .header-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}