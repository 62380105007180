.reset-password-form {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.reset-password-form__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 550px !important;
}

.reset-password-form__title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}

.success-message {
  background-color: var(--color-primary-light);
  color: var(--color-primary-darker);
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px var(--color-basic-info-light);
  border: 1px solid #c3e6cb;
}

.error-text {
  color: Var(--color-priority);
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0px;
}

.error-message {
  background-color: var(--color-priority-light);
  color: var(--color-priority-darker);
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px var(--color-basic-info-light);
  border: 1px solid var(--color-priority-light);
}

.reset-password-form .link-button {
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.reset-password-form .link-button:focus,
.reset-password-form .link-button:hover {
  color: var(--color-button-primary);
}
