@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.no-data-to-show {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out forwards;
  gap: 5px;
  font-size: 20px;
  font-weight: 300;
  word-spacing: 3px;
  color: var(--color-highlight-darker);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
