@keyframes loading {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.skeleton-loader {
  display: inline-block;
  background-color: rgba(165, 165, 165, 0.1);
  animation: loading 1.5s infinite ease-in-out;
}
