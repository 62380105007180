.conversation-appointment {
  margin: 10px 0 0 0;
  max-width: fit-content;
  font-size: 13px;
  display: flex;
  flex-direction: column;
}

.conversation-appointment-list {
  padding: 5px 0;
  display: flex;
}

.conversation-appointment-list__day {
  padding: 10px;
  border: 1px solid var(--color-primary-2);
  width: 40px;
  background-color: transparent;
  color: var(--color-primary-2);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
}

.conversation-appointment-list__info {
  display: flex;
  flex-direction: column;
}

.conversation-appointment-list__date {
  color: var(
  );
  font-weight: 600;
  margin-bottom: -5px;
  margin-top: 5px;
}

.conversation-appointment-list__sentence {
  cursor: pointer;
  padding: 10px 0;
  color: var(--color-grey-info);
}

.conversation-appointment-list__sentence:hover {
  text-decoration: underline;
}
