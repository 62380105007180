.navbar {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--color-navbar-background);
  padding: 20px;
  box-shadow: 0 -10px 20px -10px rgba(0, 0, 0, 0.07);
  z-index: 1000;

  @media(max-width: 768px) {
    padding: 5px 20px;
  }
}

.navbar-color {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  background-color: var(--color-primary-2);
  border-radius: 50px;
}

.nav-item {
  display: flex;
  margin: 0 15px;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s;
  color: white;
  background-color: transparent;
  width: 65px;
  height: 65px;
  opacity: 0.6;
}

.nav-item.active {
  color: var(--color-base-light);
  opacity: 1;
}

.icon {
  fill: currentColor;
}
