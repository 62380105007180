.info-modal {
  max-width: 400px;
  max-height: 95vh;
  min-height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  z-index: 1000;
}

.info-modal.error-modal {
  border: 2px solid var(--color-priority);
}

.text-align {
  margin-bottom: 10px;
}

.info-modal-footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.confirm-button {
  background-color: var(--color-primary);
  color: var(--color-base-light);
}

.error-button {
  background-color: transparent;
  color: var(--color-basic-info);
  border: 1px solid var(--color-grey-info);
}

.info-modal-header {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-modal-header__title {
  font-weight: 600;
  font-size: 16px;
}
