.conversation-todos__title {
  font-weight: var(--font-weight-bolder);
}

.conversation-todo__list {
  padding: 10px;
  list-style-type: disc;
  padding-left: 2px;
}

.conversation-todo__list .custom-list-item {
  padding: 5px 0;
}

.conversation-todo__list .active-circle {
  margin-right: 4px;
}
