.recording-details-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin: 15px 0;
  padding: 15px;
}

.recording-details-header__duration {
  font-size: 9px;
  color: var(--color-duration-time);
  margin-top: 5px;
  font-weight: var(--font-weight-bolder);
}

.recording-details-header__close-button {
  width: 28px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-close-button);
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
}

.recording-details-header__card-info {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.recording-details-header__info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-left: 20px;
  border-bottom: transparent !important;
}

.recording-details-header__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--color-modal-avatar-icon);
  border: transparent;
}

.recording-details-header__title {
  font-size: 14px;
}

.recording-details-header__info-date {
  color: var(--color-recording-header-date);
  font-weight: 500;
  font-size: 14px;
  opacity: 0.5;
}
