.appointment-item {
  display: flex;
  gap: 15px;
  padding: 20px 0;
}

.appointment-item__info {
  color: var(--color-grey-info);
  font-size: var(--font-size-normal);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.appointment-item__icon {
  padding: 10px;
  border: 1px solid var(--color-primary-2);
  width: 40px;
  background-color: transparent;
  color: var(--color-primary-2);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 15px;
}

.appointment-item__icon,
.appointment-item__info {
  cursor: pointer;
}

.appointment-item__icon.clickable,
.appointment-item__info.clickable {
  cursor: pointer;
}

.appointment-item__info p {
  margin: 0px;
}

.appointment-item__info div:nth-child(2) {
  color: var(--color-date-info);
}

.appointment-item__info div:nth-child(3) {
  font-weight: var(--font-weight-bolder);
}

.appointment-item__general-info {
  color: var(--color-grey-info);
  margin-right: 5px;
}

.appointment-item__highlighted-info {
  color: var(--color-appointment-highlighted-info);
  font-weight: bold;
}

.appointment-item__location {
  display: flex;
  gap: 3px;
}

.appointment-item__location span:nth-child(1) {
  font-style: italic;
}

.appointment-item__date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.appointment-item__date-day {
  font-size: 21px;
  font-weight: 600;
}

.appointment-item__date-month {
  font-size: 11px;
}
