.select-container {
  position: relative;
}

.select-native {
  border: 1px solid var(--color-highlight-darker);
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  background-color: var(--color-base-light);
  width: 100px;
}

.select-native::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: 1px solid var(--color-basic-info);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  width: 50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.select-native.open::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
