.mobile-number-input {
  width: 100%;
}

.mobile-number-list {
  margin: 0 0 25px 0;
}

.mobile-number-list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.delete-number-button {
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-number-list-title {
  margin: 10px 0 0 9px;
  font-weight: 500;
}

.private-number-label {
  font-weight: 500;
  margin: 10px 0;
}
