.todo-select-wrapper {
  display: flex;
  align-items: center;
  padding: 20px 0 0;
}

.todo-select-wrapper__select .select-native {
  border: none !important;
  color: var(--color-basic-info);
}

.todo-select-wrapper__select .select-native:focus {
  outline: none !important;
}

.todo-select-wrapper__title {
  font-weight: 700;
}

.meeting-section {
  position: relative;
  margin: 15px 0 0 15px;
}

.todo-card {
  padding: 20px 0;
  border-bottom: 1px solid var(--color-border);
  position: relative;
}

.meeting-date {
  font-size: 14px;
  line-height: 15px;
  color: var(--color-date);
  position: absolute;
  right: 0;
  top: 8px;
}

.todo-header {
  font-weight: 700;
}

.meeting-name {
  margin-bottom: 10px;
}

.meeting-todos {
  margin: 0 0 0 15px;
}