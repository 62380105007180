.todo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
}

.todo-item.custom-list-item {
  border: none !important;
  gap: 0 !important;
  list-style-type: none !important;
}

.todo-item__header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.todo-modal-item__task {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  gap: 10px;
}

.todo-item__name {
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-big);
}

.todo-modal-item__checkmark {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-primary);
}

.todo-modal-item__checkmark.checkbox-icon {
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #999999;
  border: var(--color-checkbox-icon);
}

.todo-modal-sentence {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

@media (max-width: 600px) {
  .todo-modal-sentence {
    font-size: 13px;
  }
}
