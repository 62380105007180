.recording-detail__content {
  overflow-y: auto;
  padding: 0 5px 20px 5px;
}

.recording-detail__close-button {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  padding: 0 !important;
  border: 0px !important;
}

@media (max-width: 600px) {
  .recording-detail__close-button {
    width: 35px;
    height: 35px;
  }
}

.recording-detail__buttons {
  display: flex;
  justify-content: space-evenly;
}

.recording-detail__buttons button {
  font-weight: var(--font-weight-bolder);
  width: 33%;
  min-width: fit-content;
}

.recording-detail__tab-line {
  width: 99%;
  height: 2px;
  background-color: var(--color-neutral-light);
}

.recording-detail__appointment-content > li {
  width: 100%;
  margin: 10px 0;
}

.recording-item-card__avatar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 100%;
  border-radius: 50px;
  padding: 15px;
  background-color: var(--color-primary-highlight);
}

.recording-item-card__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
}

.recording-detail__duration {
  font-size: 9px;
  color: var(--color-duration-time);
  margin-top: 5px;
  font-weight: var(--font-weight-bolder);
}

.recording-detail__header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.recording-detail__card-info {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.recording-detail__info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-left: 20px;
  border-bottom: transparent !important;
}

.recording-item__button {
  padding: 6px 10px;
  font-size: 14px;
  min-width: 64px;
  line-height: 1.75;
  background-color: transparent !important;
  color: var(--color-grey-info);
  border: none;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  transition: all .2s ease;
}

.recording-item__button-active-color {
  color: var(--color-modal-active-tab);
  border-bottom: 2px solid var(--color-modal-active-tab);
}

.recording-detail__info-date {
  color: var(--color-date-info);
  font-weight: 500;
  font-size: 14px;
}

.recording-detail__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75px;
  min-width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: var(--color-modal-avatar-icon);
  border: transparent;
  padding: 0;
}

.recording-detail__title {
  font-size: 20px;
  font-weight: 500;
}

.recording-detail__menu {
  background-color: var(--color-grey);
  border-radius: 10px;
  border: 1px solid rgba(153, 153, 153, 0.1);
  cursor: pointer;
}

.recording-detail__player-wrapper {
  position: relative;
}

.recording-detail__private-call {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 20px;
  gap: 15px;
}

.recording-detail__private-call-msg {
  text-align: center;
  padding: 15px 5px;
}

.recording-detail__private-call-switch-button {
  font-size: 14px;
  color: var(--color-private-button-text);
  background-color: var(--color-primary);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.recording-detail__private-call-switch-button:hover {
  background-color: var(--color-primary-darker);
}
