.summary__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  list-style-type: disc;
  margin-bottom: 10px;
}

.summary__list li {
  font-size: 14px;
  color: var(--color-grey-info);
  list-style-type: none;
  padding: 0;
}

.summary__match-highlight {
  font-weight: var(--font-weight-bolder);
}
