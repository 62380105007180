.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-base-light);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 10px;
}

.center {
  align-content: center;
}

.custom-modal {
  border: 3px solid var(--color-primary-2);
  padding: 20px;
  width: 100%;
  max-width: 500px;
  max-height: calc(100vh - 40px);
  min-height: 600px;
  background-color: var(--color-modal-background);
  overflow-y: auto;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 70px 0 #00000024;
}

.custom-modal::-webkit-scrollbar-track {
  background: transparent;
}

.custom-modal::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 15px;
}

.custom-modal::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 15px;
}

/* For small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .custom-modal {
    padding: 10px;
  }
}

/* For medium devices (tablets, 768px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .custom-modal {
    max-width: 80%;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.h-300 {
  min-height: 300px;
}
