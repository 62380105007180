@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-app-background);
  color: black;
}

html {
  height: 100vh;
  height: 100dvh;
}

body,
main,
#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


.container {
  width: 100%;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 15px;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-darker-white);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-highlight-darker);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-highlight-darker-2);
}


/* Base Colors */
:root {
  --color-darker-white: #f6f6f6;
  --color-neutral-light: #E8E8E8;
  --color-neutral-darker: #fafafa;
  --color-highlight-light: #d9d9d9;
  --color-highlight-darker: #c0c0c0;
  --color-highlight-darker-2: #b4b4b4;
  --color-priority: #ED2B2A;
  --color-priority-light: #f5c6cb;
  --color-priority-darker: #721c24;
  --color-priority-low: #F5BC00;
  --color-base-light: #fff;
  --color-base-darker: #BDC5CD;
  --color-basic-info: #000;
  --color-basic-info-light: rgba(0, 0, 0, 0.1);
  --color-primary: #5DB075;
  --color-primary-darker: #3F8453;
  --color-primary-light: #d4edda;
  --color-primary-2: #00C26B;
  --color-primary-highlight: #E6FFF4;
  --color-secondary: #1D85FF;
  --color-grey: #F4F4F4;
  --color-grey-info: #999999;

  --color-app-background: var(--color-base-light);
  --color-modal-active-tab: var(--color-priority-low);
  --color-modal-tab: var(--color-highlight-light);
  --color-modal-tab-hover: var(--color-highlight-darker);
  --color-appointment-highlighted-info: var(--color-basic-info);
  --color-search-bar-background: var(--color-grey);
  --color-date-info: var(--color-highlight-darker);
  --color-navbar-background: var(--color-base-light);
  --color-icon-background: var(--color-neutral-light);
  --color-menu-dropdown-background: var(--color-neutral-light);
  --color-menu-dropdown-background-hover: var(--color-highlight-darker);
  --color-modal-avatar: var(--color-darker-white);
  --color-input-background: var(--color-darker-white);
  --color-modal-background: var(--color-base-light);
  --color-border: var(--color-neutral-light);
  --color-modal-avatar-icon: var(--color-primary-highlight);
  --color-close-button: var(--color-highlight-light);
  --color-input-field-background: var(--color-highlight-light);
  --color-nav-item-background: var(--color-highlight-light);
  --color-checkbox-icon: var(--color-primary-highlight);
  --color-date: var(--color-grey-info);
  --color-duration-time: var(--color-primary-2);
  --color-text: var(--color-grey-info);
  --color-general-info: var(--color-basic-info);
  --color-button-primary: var(--color-primary);
  --color-private-button-text: var(--color-text);
  --color-recording-header-background: var(--color-neutral-light);
  --color-recording-header-date: var(--color-priority);
}

/* Typography */
:root {
  --font-size-normal: 14px;
  --font-size-big: 16px;
  --font-weight-boldest: 800;
  --font-weight-bolder: 600;
}

.fui-FluentProvider {
  height: 100%;
}

.fui-FluentProvider * {
  font-family: var(--fontFamilyBase) !important;
}

/* HELPERS */

.text-center {
  text-align: center !important;
}

.relative {
  position: relative !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
