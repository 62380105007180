.recording-transcript-wrapper{
  display: flex;
}

.recording-transcript-wrapper.top{
  align-items: start;
}

.recording-transcript-wrapper.center{
  align-items: center;
}

.recording-transcript__transcript-preview {
  position: relative;
  width: fit-content;
  overflow-y: auto;
  padding: 0 0 0 5px;
  flex: 1;
  max-height: 550px; /* Adjust the maximum height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recording-transcript__transcript-preview::-webkit-scrollbar {
  width: 0;
}

.recording-transcript__transcript-preview {
  scrollbar-width: none;
}

.recording-transcript {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.recording-transcript__details {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-border);
  padding: 10px 0;
  gap: 10px;
  min-width: 100%;
}

.recording-transcript__loader-dashed {
  display: flex;
  align-items: center;
}

.recording-transcript__speaker {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.recording-transcript__time {
  font-weight: bolder;
  color: var(--color-basic-info);
  cursor: pointer;
}

.recording-transcript__sentence {
  font-weight: 400;
  flex-grow: 1;
  display: flex;
  margin: 0;
}

.active-transcript {
  color: var(--color-secondary);
}

.recording-transcript__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-neutral-light);
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.recording-transcript__menu svg {
  width: 15px;
  height: 15px;
}

.hidden {
  display: none;
}

.recording-transcript__menu-label {
  display: none;
}

.mt-10 {
  margin-top: 20px;
}
