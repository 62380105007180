
.checkbox input {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}

.checkbox label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox .checkbox-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--grey);
  background-color: var(--color-highlight-light);
  position: relative;
  transition: all 0.3s ease;
  margin-bottom: 0 !important;
}

.checkbox .checkbox-icon:before {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-primary);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.checked {
  border-color: var(--color-primary);
}

.checkbox-icon.checked:before {
  visibility: visible;
  opacity: 1;
}
