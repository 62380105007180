.menu-dropdown-icon {
  display: inline-block;
}

.menu-dropdown {
  position: absolute;
  background-color: var(--color-app-background);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  padding: 10px 0;
  top: 100%;
  right: 6px;
  border-radius: 5px;
}

.menu-dropdown__item {
  font-size: 14px !important;
  font-weight: 500;
}

.menu-dropdown__item:hover {
  background-color: #e0e0e0;
}

.menu-dropdown__item:focus {
  background-color: #cecece;
}
