.input-field {
  padding: 15px;
  padding-right: 50px;
  border: 1px solid var(--color-border);
  border-radius: 9px;
  font-size: 16px;
  margin-bottom: 5px;
  background-color: var(--color-input-background);
  transition: all .3s ease;
}

.input-field:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px var(--color-input-background);
}

.input-field:focus {
  outline: none;
  border-color: var(--color-primary);
}

.input-field::placeholder {
  color: var(--color-input-field-background);
  opacity: 1;
  font-weight: 500;
}
