.menu-dropdown__item {
  width: auto;
  padding: 6px 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: block;
}

.menu-dropdown__item:hover {
  background-color: var(--color-darker-white);
}

.menu-dropdown__item:focus {
  outline: none;
}
