.recording-modal-header__header {
  display: flex;
  padding: 10px 0;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.recording-modal-header__close-button {
  width: 28px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-close-button);
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
}

.recording-modal-header__date-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recording-modal-header__card-info {
  display: flex;
  margin: 0 0 20px 0;
  width: 100%;
  align-items: center;
}

.recording-modal-header__info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-left: 20px;
  border-bottom: transparent !important;
}

.recording-modal-header__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 100%;
  border-radius: 20px;
  background-color: var(--color-modal-avatar-icon);
  border: transparent;
  padding: 0;
}

.recording-modal-header__title {
  font-size: 20px;
  font-weight: 500;
}

.recording-modal-header__info {
  color: var(--color-date-info);
  font-weight: 500;
  font-size: 14px;
}

.recording-modal-header__info-date {
  color: var(--color-basic-info);
  margin-left: 5px;
  line-height: 20px;
}

.recording-modal-header__delete-button {
  background-color: var(--color-priority);
  color: var(--color-darker-white);
  border: transparent;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}