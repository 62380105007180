.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-body {
  display: flex;
  width: 100%;
  padding: 0 !important;
}

.skeleton__info {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  padding: 0 15px;
}
