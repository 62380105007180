.search-bar {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: var(--color-search-bar-background);
  color: var(--color-text);
  border-radius: 50px;
  border: none;
  font-size: 16px;
  padding: 0 50px 0 20px;
}

.search-bar::placeholder {
  color: var(--color-grey-info);
  font-size: 14px;
}

.search-bar:focus {
  outline: none;
}

.search-bar-icon {
  position: absolute;
  pointer-events: none;
  right: 20px;
}

.search-bar-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
}