.loader-dots {
    font-size: 24px;
    text-align: center;
}

.loader-dots__dot {
    display: inline-block;
    margin-right: 5px;
    animation: scaleInOut 1.5s infinite alternate;
}

.loader-dots__dot:nth-child(1) {
    animation-delay: 0.1s;
}

.loader-dots__dot:nth-child(2) {
    animation-delay: 0.3s;
}

.loader-dots__dot:nth-child(3) {
    animation-delay: 0.5s;
}

.loader-dots__dot:nth-child(4) {
    animation-delay: 0.7s;
}

@keyframes scaleInOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
