.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-body {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.skeleton__info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  gap: 20px;
}

.skeleton__icons {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--color-icon-background);
}
