.login-page {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 550px !important;
}

.form-content {
  flex-grow: 0;
}

.login-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.show-password-button {
  position: absolute;
  right: 10px;
  top: 25px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--color-primary-2);
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease;
}

.show-password-button:focus,
.show-password-button:hover {
  color: var(--color-button-primary);
}

.error-text {
  color: var(--color-priority);
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0px;
}

.error-message {
  background-color: var(--color-priority-light);
  color: var(--color-priority-darker);
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px var(--color-basic-info-light);
  border: 1px solid var(--color-priority-light);
}

.login-page .link-button {
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.login-page .link-button:focus,
.login-page .link-button:hover {
  color: var(--color-button-primary);
}

@media (max-width: 768px) {
  .login-page {
    padding: 40px 20px;
  }
}
