.burger-icon {
  height: 24px;
  width: 20px;
  cursor: pointer;
  margin-top: 2px;
}

@media (max-width: 600px) {
  .burger-icon {
    width: 20px;
    height: 25px;
  }
}