.linear-progress {
  height: 4px;
  background-color: var(--color-darker-white);
  overflow: hidden;
  position: relative;
  width: 100%;
}

.progress-bar {
  background-color: var(--color-basic-info);
  height: 100%;
  position: absolute;
  left: -100%;
  width: 100%;
  animation: linearProgressAnimation 1s infinite linear;
}

@keyframes linearProgressAnimation {
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
