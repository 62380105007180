.profile-form {
  margin-top: 10px;
}

.profile-modal .input-field {
  color: var(--color-black);
  font-size: 14px;
}

.profile-modal .input-field::placeholder {
  color: var(--darker-grey1);
}

.profile-modal .input-wrapper {
  margin-bottom: 10px;
}

.form-error {
  background-color: var(--color-priority-light);
  color: var(--color-priority-darker);
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px var(--color-basic-info-light);
  border: 1px solid var(--color-priority-light);
}

.label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.border-error {
  border: 1px solid var(--color-priority) !important;
}
