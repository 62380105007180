.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-body {
  width: 100%;
  padding: 16px 0;
}

.skeleton-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.skeleton-title-wrapper {
  display: flex;
  flex-direction: column;
}

.skeleton-title {
  margin: 8px 0 5px 5px;
}

.skeleton-title-2 {
  margin: 2px 0 5px 10px;
}

.skeleton-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 6px;
}

.skeleton-list-item {
  display: flex;
}

.skeleton-checkbox {
  width: 12px;
  height: 11px;
  background-color: rgba(165, 165, 165, 0.1);
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
}
