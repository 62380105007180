.live-recording__page .custom-modal {
    min-height: fit-content;
}

.live-recording__content {
    margin: 20px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.live-recording__content audio {
    display: none;
}

.live-recording__content canvas {
    display: none;
}

.live-recording__content video {
    background-color: black;
    min-height: 50vh;
    height: 50vh;
    width: 80vw;
}

.live-recording__todays-meetings {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.live-recording__todays-meetings .new-meeting {
    min-width: 250px;
}

.live-recording__todays-meetings-list {
    border: 1px solid var(--color-border);
    padding: 10px;
    width: 100%;
}

.live-recording__todays-meetings-scrollable {
    max-height: 40vh;
    padding: 10px;
    overflow-y: scroll;
}

.live-recording__todays-meeting-item {
    text-align: center;
    border: 1px dashed var(--color-border);
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
}

.live-recording__todays-meeting-item hr {
    border-color: var(--color-base-light);
}

.live-recording__todays-meeting-start-time {
    color: #34c38f;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
}

.live-recording__todays-meeting-name {
    font-weight: bold;
}

.live-recording__todays-meeting-receiver {
    overflow-wrap: anywhere;
}

.live-recording__meeting-video {
    margin-top: 15px;
}

.live-recording__meetings-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    border-bottom: 1px solid var(--color-border);
    padding: 10px;
    width: 100%;
}

.live-recording__recording-in-progress-preview {
    text-align: center;
}

.live-recording__video-warning {
    padding: 10px;
    background-color: palegoldenrod;
    border-radius: 5px;
}

.live-recording__meetings-data .meeting-title {
    width: 50%;
    min-width: 250px;
}

.live-recording__meetings-data .participant-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    width: 95%;
    margin-top: 15px;
}

.live-recording__meetings-data .participant-data .input-field {
    width: 100%;
}

.live-recording__meetings-data .participant-data .remove-participant {
    width: 50%;
    min-width: fit-content;
    margin-top: 10px;
}

.live-recording__meetings-data .add-participant {
    width: 50%;
    min-width: fit-content;
}

.live-recording__meetings-data .add-participant.add-participant-text {
    width: 50%;
}

.live-recording__meetings-data .participants-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 10px;
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 25px;
    width: 50%;
    min-width: 250px;
}

.live-recording__meetings-data .update-meeting-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.live-recording__meetings-data .update-meeting {
    width: 50%;
    min-width: 250px;
}

.live-recording__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    margin: 0 10px;
}

.live-recording__header .stop-recording {
    background-color: var(--color-priority);
    filter: contrast(0.85);
    color: white;
}

.live-recording__header .stop-recording:disabled {
    background-color: #929292;
}

.button-tooltip-container {
    align-items: center;
    width: 100%;
    position: relative;

    &[data-tooltip] {
        &:after {
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            color: black;
            border: 1px solid black;
            padding: 5px;
            padding-top: 7px;
            content: attr(data-tooltip);
            font-weight: normal;
            font-size: small;
            width: max-content;
            transition: opacity 0.75s ease-in-out;
            -webkit-transition: opacity 0.75s ease-in-out;
            z-index: 1;
            display: none;
            @media (max-width: 500px) {
                max-width: 95vw;
                text-align: center;
            }
        }
    }

    &[data-tooltip]:hover:after {
        display: unset;
    }
}

.select-another-meeting {
    width: 50%;
    min-width: 250px;
}

.live-recording__video-switches {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 10px;
}

.live-recording__video-switches label {
    padding: 5px;
    display: flex;
    gap: 5px;
}
