.todo-item {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--color-grey-info);
}

.todo-item.custom-list-item {
  border: none !important;
  gap: 0 !important;
  list-style-type: none !important;
}

.todo-item__header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.todo-item__task {
  padding: 0;
}

.todo-item__task div {
  position: relative;
  list-style-type: none;
}

.todo-item__name {
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-big);
}
