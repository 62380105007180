.recording-truncated-transcript {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 5px 0 5px;
}

.recording-truncated-transcript__details {
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    max-width: 400px;
}

.recording-truncated-transcript__loader-dashed {
    display: flex;
    align-items: center;
}

.recording-truncated-transcript__speaker {
    white-space: nowrap;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
}

.recording-truncated-transcript__sentence {
    font-weight: 400;
}

.recording-truncated-transcript__details .recording-truncated-transcript__sentence {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 430px) {
    .recording-truncated-transcript__details {
    }

    .recording-truncated-transcript__details .recording-truncated-transcript__sentence {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 768px) {
    .recording-truncated-transcript__details {
        max-width: 300px;
    }
}
