.custom-list {
  margin: 0;
  list-style: none;
  overflow: hidden;
  padding: 10px 0 0;
}

.scrollable.container {
  overflow-y: scroll;
  max-height: 100%;
}

::-webkit-scrollbar-track {
  background: transparent;
}