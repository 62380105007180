.checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  word-break: break-word;
  max-width: 100%;
}

.checkbox-icon {
  min-width: 25px;
  min-height: 18px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--color-checkbox-icon) !important;
  padding-right: 5px;
}

.checked {
  fill: var(--color-primary-highlight);
}

.active-circle {
  background-color: var(--color-primary-highlight);
  width: 7px;
  height: 7px;
  display: block;
  transform: translate(0,6px)
}
