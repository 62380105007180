.button {
  width: 100%;
  padding: 15px;
  background-color: var(--color-primary-2);
  color: var(--color-darker-white);
  border: none;
  border-radius: 50px;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 600;
  transition: all .3s ease;
}

.button:focus,
.button:hover {
  background-color: var(--color-button-primary);
}

.button[disabled] {
  filter: grayscale();
}

.button.secondary-button {
  background-color: transparent;
  color: var(--color-basic-info);
  border: 1px solid var(--color-grey-info);
}
